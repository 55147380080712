import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationBehaviorOptions,
  NavigationExtras,
  Params,
  Router
} from '@angular/router';
import { LoggingService } from '@infosysbub/ng-lib-dpl3';
import { BehaviorSubject, Observable, from, shareReplay } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

@Injectable()
export class UrlParamService {
  // Separators
  public static VALUE_SEPARATOR = ';';

  // Parameter Constants - Searchparameters
  public static PARAM_ORTE = 'ort';
  public static PARAM_STUDIENFAECHER = 'sfa';
  public static PARAM_STUDIENFELDER = 'sfe';
  public static PARAM_SUCHWORTE = 'sw';
  public static PARAM_AUTOCOMPLETE = 'ac';
  public static PARAM_UK = 'uk';
  public static PARAM_SUCHTYP = 'sty';

  // Parameter Constants - Filterparameters
  public static PARAM_STUDIENFORMEN = 'sfo';
  public static PARAM_HOCHSCHULART = 'hsa';
  public static PARAM_ABSCHLUSSGRAD = 'abg';
  public static PARAM_STUDIENMODELL = 'smo';
  public static PARAM_STUDIENANBIETER = 'san';

  //                     - Fit fuers Studium
  public static PARAM_FFSTUDIUM = 'ffst';
  //                     - Studientypen
  public static PARAM_STUDT = 'st';
  //                     - Regionen (Bundesländer und Nachbarländer)
  public static PARAM_REGION = 're';

  public static FILTER_PARAMS: string[] = [
    UrlParamService.PARAM_STUDIENFORMEN,
    UrlParamService.PARAM_HOCHSCHULART,
    UrlParamService.PARAM_ABSCHLUSSGRAD,
    UrlParamService.PARAM_STUDT,
    UrlParamService.PARAM_FFSTUDIUM,
    UrlParamService.PARAM_REGION,
    UrlParamService.PARAM_STUDIENMODELL,
    UrlParamService.PARAM_STUDIENANBIETER
    // ? page
  ];

  // Parameter Constants - misc
  public static PARAM_PAGE = 'pg';
  public static PARAM_ANSICHT = 'at';

  public currentParams: BehaviorSubject<Map<string, string>> = new BehaviorSubject<
    Map<string, string>
  >(new Map<string, string>());

  private urlSearchParams: HttpParams;

  /**
   * Der Konstruktor wird mit dem Router, der aktive Route sowie dem Logger aufgerufen.
   *
   * @param router
   * @param activeRoute
   * @param logger
   */
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private logger: LoggingService
  ) {
    // Wir müssen über Änderungen in der Browser-Route informiert werden, weil sonst
    // die korrekte Abfrage der Parameter nach einem Browser-Back / -Forward nicht möglich
    // ist.
    activeRoute.queryParams.subscribe((params) => {
      let p: Params = params;
      let m = new Map<string, string>();

      this.urlSearchParams = new HttpParams();
      for (let key in p) {
        if (params.hasOwnProperty(key)) {
          this.urlSearchParams = this.urlSearchParams.set(key, p[key]);
          m.set(key, p[key]);
        }
      }
      this.currentParams.next(m);
    });
  }

  /**
   * Löscht alle Filter Parameter
   */
  public resetFilter() {
    for (let param of UrlParamService.FILTER_PARAMS) {
      this.urlSearchParams = this.urlSearchParams.delete(param);
    }
  }

  /**
   * Liefert die aktuellen URL-Parameter für Weitergabe ins Backend, ggf. ergänzt um die angegebenen Parameter.
   *
   * Nur belegte Parameter werden ausgeliefert (die anderen werden entfernt).
   *
   * @param params Das Objekt mit den zu ergänzenden Parametern.
   */
  public getUrlParamsForBackend(params?: {}): HttpParams {
    return this.getSearchParams(params);
  }

  /**
   * Liefert die aktuellen URL-Parameter fürs Frontend.
   */
  public getUrlParamsForFrontend(): HttpParams {
    return this.getSearchParams();
  }

  /**
   * Liefert ein Params-Objekt mit den gespeicherten Parameter, ggf. ergänzt um die angegebenen Parameter.
   *
   * Dieses Ergebnis kann direkt vom [queryParams] Parameter für einen routerLink verwendet werden.
   *
   * @param params Das JSON-Objekt mit den zu ergänzenden Parametern.
   */
  public getQueryParams(params?: Params): Params {
    let p = this.getSearchParams(params);
    let ret: Params = {};
    p.keys().forEach((key) => (ret[key] = p.get(key)));
    return ret;
  }

  /**
   * Gibt an, ob ein bestimmter Parameter mit einem bestimmten Wert gesetzt ist.
   *
   * @param paramKey zu prüfender Parametername
   * @param paramValue Sollwert des Parameters
   * @returns {boolean} true, wenn der Parameter mit dem Wert gesetzt it, andernfalls false.
   */
  public hasParamWithValue(paramKey: string, paramValue: string) {
    let value = this.urlSearchParams.get(paramKey);
    return value != null && value != 'null' && value === paramValue;
  }

  /**
   * Aktualisiert die aktuelle Seite.
   *
   * Als Query-Parameter werden alle aktuellen verwendet, die ggf.
   * mit den angegebenen params überschrieben bzw. angereichert werden.
   * @param params z.B. {[foo]: bar, [x]: y}
   * @param options z. B. { replaceUrl:true }
   */
  public updateView(params?: Params, options?: NavigationBehaviorOptions) {
    this.urlSearchParams = this.getSearchParams(params);
    let path = this.router.url.split('?')[0];
    this.navigateTo(path, null, options);
  }

  /**
   * Ruft eine bestimmte Seite auf.
   * Als Query-Parameter werden alle aktuellen verwendet, die ggf.
   * mit den angegebenen params überschrieben bzw. angereichert werden.
   * @param path z.B. /suche
   * @param params z.B. {[foo]: bar, [x]: y}
   * @param options z. B. { replaceUrl:true }
   */
  public navigateTo(path: string, params?: Params, options?: NavigationBehaviorOptions) {
    let newParams: HttpParams = this.getSearchParams(params);
    this.router.navigateByUrl(path + '?' + newParams.toString(), options);
  }

  /**
   * Naivgation auf Basis eines Startpunktes. Wenn kein Startpunkt gegeben ist, wird die Navigation absolut behandelt.
   * Sofern über navigationExtras nicht explizit übergeben werden, werden Url Parameter bei der Navigation beibehalten.
   *
   * @param commands array der zu navigierenden routen
   * @param navigationExtras repräsentiert zusätzliche Optionen für die Navigation (z.B. relativeTo, queryParams, etc...)
   * @returns {Observable<boolean>} Observable, durch das noch weitere Aktionen eingereiht werden koennen,
   *                                die nach dem Navigieren ausgefuehrt werden
   */
  public navigate(commands: string[], navigationExtras?: NavigationExtras): Observable<boolean> {
    let ne: NavigationExtras = {
      queryParamsHandling: 'merge'
    };
    const extras = navigationExtras ? navigationExtras : ne;
    return from(this.router.navigate(commands, extras));
  }

  public isDualesStudiumSet(params: Params | HttpParams) {
    let studienmodell: string;
    if (params instanceof HttpParams) {
      studienmodell = params.get(UrlParamService.PARAM_STUDIENMODELL);
    } else {
      studienmodell = params[UrlParamService.PARAM_STUDIENMODELL];
    }
    let dualesStudiumSet = false;
    if (studienmodell != null) {
      dualesStudiumSet =
        studienmodell === '5' ||
        studienmodell.startsWith('5;') ||
        studienmodell.indexOf(';5;') > -1 ||
        studienmodell.endsWith(';5');
    }
    return dualesStudiumSet;
  }

  /**
   * Liefert die aktuellen URL-Parameter, ggf. ergänzt um die angegebenen Parameter.
   *
   * Nur belegte Parameter werden ausgeliefert (die anderen werden entfernt).
   *
   * @param params Das Objekt mit den zu ergänzenden Parametern.
   */
  public getSearchParams(params?: Params): HttpParams {
    let ret = this.urlSearchParams;

    if (params) {
      for (let key in params) {
        if (params.hasOwnProperty(key)) {
          ret = ret.set(key, params[key]);
        }
      }
    }
    // leere Parameter entfernen
    ret.keys().forEach((key) => {
      // nach der Umstellung auf Angular 12 wird null Wert als String an uns übergeben
      if (
        ret.get(key) == 'null' ||
        ret.get(key) == null ||
        ret.get(key).length === 0 ||
        (ret.get(key).length === 1 && ret.get(key)[0].length === 0)
      ) {
        ret = ret.delete(key);
      }
    });
    return ret;
  }

  public getParam(paramKey: string): string {
    return this.urlSearchParams.get(paramKey);
  }

  public isStudienfeldOrStudienfachSet() {
    let params = this.getSearchParams();
    return (
      params.get(UrlParamService.PARAM_STUDIENFELDER) != null ||
      params.get(UrlParamService.PARAM_STUDIENFAECHER) != null
    );
  }

  public isSuchwortSet() {
    let params = this.getSearchParams();
    return params.get(UrlParamService.PARAM_SUCHWORTE) != null;
  }

  public getQueryParameterString(): Observable<string> {
    return this.activeRoute.queryParamMap.pipe(
      map((params) => {
        return '?' + params.keys.map((key) => key + '=' + params.get(key)).join('&');
      }),
      distinctUntilChanged(),
      shareReplay()
    );
  }
}
